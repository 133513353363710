<template>
  <div class="address">
    <div class="address-img">
      <img src="../assets/image/banner_callus.png" alt="" />
    </div>
    <p class="lianxi">联系方式</p>
    <p class="lianxiadress">您可以通过以下联系方式联系我们</p>
    <div class="address-center">
      <div class="address-center-left">
        <h3>陕西福音康复集团</h3>
        <p>
          福音集团致力于人类骨骼健康事业的研究与发展，积累了庞大的骨骼病例数据库
        </p>
        <p>目前业务遍布陕西省各地。</p>
        <p>客服电话：029-6888 2888</p>
        <p>服务时间：周一至周日9:00-18:00</p>
        <p>测评地址：陕西省西安市未央区经开万科中心15层</p>
        <p>公司总部：陕西省西安市莲湖区西关正街“晨光御苑”24层。</p>
      </div>
      <div class="address-center-right">
        <baidu-map
          :center="center"
          :zoom="zoom"
          @ready="handler"
          style="width: 500px; height: 300px"
          @click="getClickInfo"
          :scroll-wheel-zoom="true"
          
        >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
        </baidu-map>
      </div>
    </div>
     <div class="home-btn">
      <p>地址：陕西省西安市未央区经开万科中心15层 | 电话：029-6888-2888</p>

      
      <div style="width: 400px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61010402000448"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="../assets/image/BA.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            陕公网安备 61010402000448号
          </p></a
        >
        <a
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          href="https://beian.miit.gov.cn/"
        >
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            陕ICP备18005906-1
          </p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: { lng: 108.954721, lat: 34.355478 },
      zoom: 17,
    };
  },
  methods: {
    handler({ BMap, map }) {
      var point = new BMap.Point(108.954308, 34.355322);
      map.centerAndZoom(point, 17);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 6, {
        strokeColor: "Red",
        strokeWeight: 6,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
  },
};
</script>

<style lang="scss" scoped>
.address-img {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
  }
}
.lianxi {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 25px;
  color: #3f8e0c;
}
.lianxiadress {
  width: 100%;

  text-align: center;
  font-size: 14px;
  color: #9d9d9d;
}
.address-center {
  display: flex;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 10px;
  height: 350px;
  //   background-color: pink;
  .address-center-left {
    box-sizing: border-box;
    width: 50%;
    height: 350px;
    padding-left: 20%;
    h3 {
      margin-bottom: 30px;
      color: #4b4b4b;
    }
    p {
      margin-top: 15px;
      color: #9d9d9d;
    }
  }
  .address-center-right {
    box-sizing: border-box;
    width: 50%;
    height: 350px;
    padding-left: 5%;
  }
}
.home-btn {
  p {
    text-align: center;
    color: #9d9d9d;
  }
  > a {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #9d9d9d;
  }
  .beian {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    a {
      color: #9d9d9d;
    }
  }
}
</style>